export default async () => {
  const requestUrlDict = {
    localhost: 'https://localhost:44300',
    'rr-dev.ru': 'https://rr-dev.ru',
    default: '',
  };

  const baseUrl = requestUrlDict[window.location.hostname] || requestUrlDict.default;

  const response = await fetch(`${baseUrl}/api/events/today-events-count`, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      Accept: 'text/json;charset=utf-8',
    },
    mode: 'no-cors',
  });

  const eventsAmount = await response.text();

  return eventsAmount ? Number(eventsAmount) : 0;
};
