<template>
  <HeaderNew
    :theme="theme"
    :user="user"
    :state="state"
    :logoImg="themeImages.logo"
    :logoAlt="themeName"
    :loginUrl="`${themeUrls.Events}/login`"
    :menu="menu"
    :mobileMenu="menu"
    :profileMenu="profileMenu"
    :live="isLive"
    :liveUrl="isLive ? themeUrls.Live : ''"
    :moreButtonText="$t('nav.more')"
    :locales="themeSettings.languages"
    :locale="$lang"
    :logoUrl="logoUrl"
    :logoutUrl="`${themeUrls.Events}/Auth/LogOff`"
    :logoutText="$t('profile.sign_out')"
    :isFlagsLang="true"
    align="center"
    @changeLang="changeLang"
  ></HeaderNew>
</template>

<script>
import getTodayEventsAmount from '@/assets/js/getTodayEventsAmount';
import { mapGetters } from 'vuex';
import eventsApi from '@/services/events-api';
import { setCulture } from '@/utils';
import HeaderNew from '../../HeaderNew/Header.vue';
import ru from '../locales/ru.json';
import en from '../locales/en.json';
import uz from '../locales/uz.json';

let themeUrls = null;

export default {
  name: 'SamarkandHeader',
  i18n: {
    messages: {
      ru,
      en,
      uz,
    },
  },
  components: {
    HeaderNew,
  },
  data() {
    return {
      menu: [
        {
          id: 1,
          text: this.$t('nav.events'),
          href: `${themeUrls.Events}/events`,
          icon: 'calendar',
          items: [
            {
              text: this.$t('nav.upcoming'),
              href: `${themeUrls.Events}/events`,
            },
            {
              text: this.$t('nav.justNow'),
              href: `${themeUrls.Events}/events?e=1`,
              hidden: true,
            },
            {
              text: this.$t('nav.past'),
              href: themeUrls.Results,
            },
          ],
          open: false,
          itemsOnlyMobile: true,
        },
        {
          id: 2,
          text: this.$t('nav.results'),
          href: themeUrls.Results,
          icon: '1st-place-medal',
        },
        // {
        //   id: 3,
        //   text: this.$t('nav.about'),
        //   href: '',
        //   icon: 'finish',
        //   items: [
        //     {
        //       text: this.$t('nav.mission'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site%2Fmission',
        //     },
        //     {
        //       text: this.$t('nav.media_center'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site%2Fabout',
        //     },
        //     {
        //       text: this.$t('nav.partners'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site%2Fabout',
        //     },
        //   ],
        // },
        // {
        //   id: 4,
        //   text: this.$t('nav.participants'),
        //   href: '',
        //   icon: 'user',
        //   items: [
        //     {
        //       text: this.$t('nav.question_answer'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site1%2Finstruction',
        //     },
        //   ],
        // },
        // {
        //   id: 5,
        //   text: this.$t('nav.inclusion'),
        //   href: '',
        //   icon: 'read',
        //   items: [
        //     {
        //       text: this.$t('nav.lectures'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site1%2Flecture',
        //     },
        //     {
        //       text: this.$t('nav.master_classes'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site1%2Fmaster',
        //     },
        //   ],
        // },
        // {
        //   id: 6,
        //   text: this.$t('nav.culture'),
        //   href: '',
        //   icon: 'play-circle',
        //   items: [
        //     {
        //       text: this.$t('nav.films'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site1%2Ffilm',
        //     },
        //     {
        //       text: this.$t('nav.online_concert'),
        //       href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site%2Fabout',
        //     },
        //   ],
        // },
        // {
        //   id: 7,
        //   text: this.$t('nav.contacts'),
        //   href: 'https://www.samarkandhalfmarathon.uz/index.php?r=site%2Fcontact',
        //   icon: 'phone',
        // },
      ],
      profileMenu: [
        {
          name: this.$t('profile.profile'),
          icon: 'user',
          link: `${themeUrls.Events}/Account`,
        },
        {
          name: this.$t('profile.registrations'),
          icon: 'badge',
          link: `${themeUrls.Events}/Account/RegistrationList`,
        },
        {
          name: this.$t('profile.orders'),
          icon: 'shopping-bag',
          link: `${themeUrls.Events}/Account/OrderList`,
        },
        {
          isDivider: true,
          roles: ['isAdmin', 'isBeneficiary'],
        },
        {
          name: this.$t('profile.organizers'),
          icon: 'caret-right',
          link: 'https://admin.russiarunning.com/',
          roles: ['isAdmin', 'isBeneficiary'],
        },
        {
          name: this.$t('profile.bills'),
          icon: 'caret-right',
          link: `${themeUrls.Events}/Account/Bills`,
          roles: ['isAdmin'],
        },
        {
          name: this.$t('profile.cache'),
          icon: 'caret-right',
          link: `${themeUrls.Events}/CacheAdmin`,
          roles: ['isAdmin'],
        },
      ],
      isLive: false,
    };
  },
  computed: {
    ...mapGetters('settings', ['theme', 'themeImages', 'themeName', 'themeUrls', 'themeSettings']),
    ...mapGetters('user', ['user', 'isAuthorized']),
    state() {
      if (this.isAuthorized) {
        return 'authorized';
      }
      return 'unauthorized';
    },
    logoUrl() {
      return window.location.hostname === 'localhost' ? '/' : 'https://events.samarkandmarathon.uz';
    },
  },
  methods: {
    async getLiveCount() {
      const result = await eventsApi.getLiveCount();
      if (result.data > 0) {
        this.isLive = true;
      }
    },
    changeLang(lang) {
      let origin = this.themeUrls.Events;

      if (window.location.hostname === 'localhost') {
        origin = 'https://localhost:44300/';
      }

      setCulture(lang, origin);
    },
  },
  mounted() {
    this.getLiveCount();

    const todayEventsItem = this.menu[0].items[1];

    if (!todayEventsItem) return;

    getTodayEventsAmount().then((eventsAmount) => {
      todayEventsItem.hidden = eventsAmount === 0;
    });
  },
  beforeCreate() {
    themeUrls = this.$store.getters['settings/themeUrls'];
  },
};
</script>
